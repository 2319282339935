import { Component } from '@angular/core';
//
import { MasterPageService } from '../../master-page.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {

  constructor(
    public masterPageService: MasterPageService
  ) {
  }

}
