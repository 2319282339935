// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  google: {
    MAP_API_KEY: 'AIzaSyBEm9i7DO3izYry3bMKPHjTyKWBUjxDME4',
    GA_TRACKING_ID: 'UA-51810071-1',
    RECAPTCHA_SITE_KEY: '6LeuYCgTAAAAAFl6BryRH4lFlu9Z9M6YQaCQL3Fv'
  },
  baidu: {
    MAP_API_KEY: '',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
