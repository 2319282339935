import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-exhibition-trends',
  templateUrl: './exhibition-trends.component.html',
  styleUrls: ['./exhibition-trends.component.scss']
})
export class ExhibitionTrendsComponent implements OnInit {

    modalRef: BsModalRef;
    title: string;
    videoUrl: string;
    constructor(private modalService: BsModalService) { }

    openModal(template: TemplateRef<any>, title: string, videoUrl: string) {
      this.title = title;
      this.videoUrl = videoUrl;
      // this.videoEle.nativeElement.src = videoUrl;
      this.modalRef = this.modalService.show(
        template,
        Object.assign({}, { animated: false, class: 'modal-lg' })
      );
    }

    ngOnInit() {
    }

}
