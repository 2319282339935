import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-build-your-app',
  templateUrl: './build-your-app.component.html',
  styleUrls: ['./build-your-app.component.scss']
})
export class BuildYourAppComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit() {

    // console.log(this.owlCarouselComponent);
  }

}
