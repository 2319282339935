import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-in-style-you-need',
  templateUrl: './in-style-you-need.component.html',
  styleUrls: ['./in-style-you-need.component.scss']
})
export class InStyleYouNeedComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit() {

    // console.log(this.owlCarouselComponent);
  }

}
