import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-digpro',
  templateUrl: './about-digpro.component.html',
  styleUrls: ['./about-digpro.component.scss']
})
export class AboutDigproComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit() {

    // console.log(this.owlCarouselComponent);
  }

}
