import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlatformBrowserHelpersModule } from 'ngx-ec-libs';

import { ASceneTmComponent } from './components/a-scene-tm';
import { ASceneVenueComponent } from './components/a-scene-venue';

import { ASceneTmService } from './services/a-scene-tm';

@NgModule({
  imports: [
    CommonModule,
    PlatformBrowserHelpersModule,
  ],
  declarations: [
    ASceneTmComponent,
    ASceneVenueComponent,
  ],
  exports: [
    ASceneTmComponent,
    ASceneVenueComponent,
  ],
  providers: [
    ASceneTmService,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AFrameHelpersModule {
  static forRoot(providers = []): ModuleWithProviders {
    return {
      ngModule: AFrameHelpersModule,
      providers: [
        ...providers,
      ]
    };
  }
}
