import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
// import { shuffle } from 'lodash';
// import { ResponsiveState } from 'ngx-responsive';

declare var THREE: any;

@Component({
  selector: 'a-scene-venue',
  styles: [`
.a-scene-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: calc(100vw - 10px);
}
a-scene {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  width: calc(100vw - 10px);
}`],
  templateUrl: './a-scene-venue.html',
})
export class ASceneVenueComponent implements OnInit, AfterViewInit {

  cameraRotation = '';
  aAssetsLoaded = false;

  imgs = [
    { src: '/assets/imgs/show-in-kiosk/screens/1.png', },
    { src: '/assets/imgs/show-in-kiosk/screens/2.png', },
    { src: '/assets/imgs/show-in-kiosk/screens/3.png', },
    { src: '/assets/imgs/show-in-kiosk/screens/4.png', },
    { src: '/assets/imgs/show-in-kiosk/screens/5.jpg', },
  ];
  bootanimationImgs = [
    // { src: '/assets/imgs/show-in-kiosk/bootanimation/1.png', },
    { src: '/assets/imgs/show-in-kiosk/bootanimation/2.png', },
  ];
  venueLeftImgs = [
    { src: '/assets/imgs/bg-kiosk/fashion_1_left.jpg' },
    { src: '/assets/imgs/bg-kiosk/fashion_2_left.jpg' },
  ];
  venueRightImgs = [
    { src: '/assets/imgs/bg-kiosk/fashion_1_right.jpg' },
    { src: '/assets/imgs/bg-kiosk/fashion_2_right.jpg' },
  ];

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  onAAssetsLoaded($event): void {
    console.log('onAAssetsLoaded', $event);

    // setTimeout(() => {
    //   this.aAssetsLoaded = true;
    //   setTimeout(() => {
    //   })
    // })
  }
}
