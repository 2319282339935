import { Component } from '@angular/core';

import { MasterPageService } from '../../master-page.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  constructor(
    public masterPageService: MasterPageService
  ) {
  }
}
