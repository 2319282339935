import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutDigproComponent } from './pages/about-digpro/about-digpro.component';
import { BuildYourAppComponent } from './pages/build-your-app/build-your-app.component';
import { BallFiringGamesComponent } from './pages/ball-firing-games/ball-firing-games.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { DigitalSignageComponent } from './pages/digital-signage/digital-signage.component';
import { InStyleYouNeedComponent } from './pages/in-style-you-need/in-style-you-need.component';
import { MediaGalleryComponent } from './pages/media-gallery/media-gallery.component';
import { MobileAppComponent } from './pages/mobile-app/mobile-app.component';
import { RentalServicesComponent } from './pages/rental-services/rental-services.component';
import { ExhibitionTrendsComponent } from './pages/exhibition-trends/exhibition-trends.component';

export const AppRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about-digpro', component: AboutDigproComponent },
  { path: 'build-your-app', component: BuildYourAppComponent },
  { path: 'ball-firing-games', component: BallFiringGamesComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'digital-signage', component: DigitalSignageComponent },
  { path: 'in-style-you-need', component: InStyleYouNeedComponent },
  { path: 'mobile-app', component: MobileAppComponent },
  { path: 'media-gallery', component: MediaGalleryComponent },
  { path: 'rental-services', component: RentalServicesComponent },
  { path: 'exhibition-trends', component: ExhibitionTrendsComponent },
  // Redirect before 2019-08-09
  { path: 'Welcome_to_Touch_Media.html', redirectTo: '/home', pathMatch: 'prefix' },
  { path: 'Welcome_to_Touch_Media.html', redirectTo: '/home', pathMatch: 'prefix' },
  { path: 'Touch_Media_Remote_App.html', redirectTo: '/mobile-app', pathMatch: 'prefix' },
  { path: 'Digital_Signage_Kiosk.html', redirectTo: '/mobile-app', pathMatch: 'prefix' },
  { path: 'In_Style_You_Need.html', redirectTo: '/mobile-app', pathMatch: 'prefix' },
  { path: 'Building_Your_Software.html', redirectTo: '/mobile-app', pathMatch: 'prefix' },
  { path: 'Rent_Digital_Signage.html', redirectTo: '/mobile-app', pathMatch: 'prefix' },
  { path: 'About_Digpro.html', redirectTo: '/mobile-app', pathMatch: 'prefix' },
  { path: 'Contact_Us.html', redirectTo: '/mobile-app', pathMatch: 'prefix' },
  { path: '**', redirectTo: '/home' },
];
