import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rental-services',
  templateUrl: './rental-services.component.html',
  styleUrls: ['./rental-services.component.scss']
})
export class RentalServicesComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit() {

    // console.log(this.owlCarouselComponent);
  }

}
