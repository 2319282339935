import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { filter, distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

//
import { snakeCase } from "lodash";

export interface BreadCrumb {
  label: string;
  url: string;
};

export interface PageTitle {
  label: string;
};

@Injectable({
  providedIn: 'root'
})
export class MasterPageService {

  showOutlet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  showPreloader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // isinsidePage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  // showPageTitle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  // showBreadcrumb$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  breadcrumbs$: BehaviorSubject<Array<BreadCrumb>> = new BehaviorSubject<Array<BreadCrumb>>([]);
  breadcrumbs$$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    distinctUntilChanged(),
    map(() => this.buildBreadCrumb(this.activatedRoute.root))
  );

  pageTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  pageTitle$$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    distinctUntilChanged(),
    map(() => this.buildPageTitle(this.activatedRoute.root))
  );

  constructor(
    private activatedRoute: ActivatedRoute
    , private router: Router
    , private title: Title
    , private meta: Meta
    , private translateService: TranslateService
  ) {
    this.pageTitle$$.subscribe((t) => this.pageTitle$.next(t));
    this.breadcrumbs$$.subscribe((t) => this.breadcrumbs$.next(t));
  }

  ngOnInit() {
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', parentLabel: string = null, breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    // If no routeConfig is avalailable we are on the root path
    const label = (parentLabel ? (parentLabel + "_.") : "") + (route.routeConfig ? snakeCase(route.routeConfig.path).toUpperCase() : 'HOME');
    const path = route.routeConfig ? route.routeConfig.path : '';
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = `${url}${path}/`;
    const breadcrumb = {
      label: "BREADCRUMB." + label,
      url: nextUrl,
    };
    const newBreadcrumbs = [...breadcrumbs, breadcrumb];

    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, label, newBreadcrumbs);
    }
    return newBreadcrumbs.slice(0, -1);
  }

  buildPageTitle(route: ActivatedRoute, parentLabel: string = null): string {
    const label = (parentLabel ? (parentLabel + "_.") : "") + (route.routeConfig ? snakeCase(route.routeConfig.path).toUpperCase() : '');

    if (route.firstChild && undefined !== route.firstChild.routeConfig['path'] && "" !== route.firstChild.routeConfig['path']) {
      return this.buildPageTitle(route.firstChild, label);
    }

    this.translateService.get("META_TITLE." + label).subscribe(x => {
      this.title.setTitle(x + '');
    });

    this.translateService.get("META_DESCRIPTION." + label).subscribe(x => {
      this.meta.updateTag({ name: 'description', content: x + '' });
    });

    this.translateService.get("META_KEYWORDS." + label).subscribe(x => {
      this.meta.updateTag({ name: 'keywords', content: x });
    });

    return "PAGE_TITLE." + label;
  }
}
