import { BrowserModule } from '@angular/platform-browser';
import { Inject, InjectionToken, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { CommonModule } from '@angular/common';

// Ng universal
// import { TransferHttpCacheModule } from '@nguniversal/common';

//
import { NgtUniversalModule } from '@ng-toolkit/universal';

// NgxTranslate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
// import { WebpackTranslateLoaderFactory } from '@nx/ngx-translate-helpers';
import { WebpackTranslateLoaderFactory } from 'ngx-ec-libs';

// NgxBootstrap
// import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';

//
// import { InViewportModule } from '@thisissoon/angular-inviewport';

// ngx-swiper
// import { SwiperModule } from 'ngx-swiper-wrapper';
// import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
// import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
//
// const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
//   direction: 'horizontal',
//   slidesPerView: 'auto'
// };

// ngx-masonry
// import { NgxMasonryModule } from 'ngx-masonry';

// import { NgxTypedJsModule } from 'ngx-typed-js';

import { ResponsiveModule } from 'ngx-responsive';

// import { Angulartics2Module } from 'angulartics2';

// Angular ReCaptcha
import { RecaptchaModule } from 'ng-recaptcha';

// Angular Google Maps
import { AgmCoreModule } from '@agm/core';

// ngx-ec-libs
import { PlatformBrowserHelpersModule } from 'ngx-ec-libs';
import { QrCodeHelpersModule } from 'ngx-ec-libs';
import { NgxEcSiteModule } from 'ngx-ec-site';

// @nx
import { AFrameHelpersModule } from '@nx/aframe-helpers';

// This App
import { environment } from '../environments/environment';
import { AppRoutes } from './routes';
import { AppComponent } from './app.component';
//
import { AppSharedModule } from './app-shared/app-shared.module';
import { MasterPageModule } from './master-page/master-page.module';
//
import { HomeComponent } from './pages/home/home.component';
import { AboutDigproComponent } from './pages/about-digpro/about-digpro.component';
import { ExhibitionTrendsComponent } from './pages/exhibition-trends/exhibition-trends.component';
import { BuildYourAppComponent } from './pages/build-your-app/build-your-app.component';
import { BallFiringGamesComponent } from './pages/ball-firing-games/ball-firing-games.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { DigitalSignageComponent } from './pages/digital-signage/digital-signage.component';
import { InStyleYouNeedComponent } from './pages/in-style-you-need/in-style-you-need.component';
import { MediaGalleryComponent } from './pages/media-gallery/media-gallery.component';
import { MobileAppComponent } from './pages/mobile-app/mobile-app.component';
import { RentalServicesComponent } from './pages/rental-services/rental-services.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';

declare var System: System;
interface System {
  import(request: string): Promise<any>;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutDigproComponent,
    ExhibitionTrendsComponent,
    BuildYourAppComponent,
    BallFiringGamesComponent,
    ContactUsComponent,
    DigitalSignageComponent,
    InStyleYouNeedComponent,
    MediaGalleryComponent,
    MobileAppComponent,
    RentalServicesComponent,
    PageHeaderComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule.forRoot(
      AppRoutes,
      // { enableTracing: true } // <-- debugging purposes only
    ),
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    //
    // TransferHttpCacheModule,
    NgtUniversalModule,
    // ngx-translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: WebpackTranslateLoaderFactory((lang) => ({
          "": System.import(`../assets/i18n/${lang}.json`),
        })),
        deps: [],
      },
    }),
    // Google Services
    // Angulartics2Module.forRoot(),
    RecaptchaModule,
    AgmCoreModule.forRoot(),
    // ngx-bootstrap
    // AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    //
    // SwiperModule,
    ResponsiveModule.forRoot(),
    // InViewportModule,
    //
    QrCodeHelpersModule.forRoot(),
    PlatformBrowserHelpersModule.forRoot(),
    NgxEcSiteModule.forRoot({
      domain: 'digpro-vision.com',
      locale: {
        default: 'en',
        allowed: ['en'],
      },
      environment: environment
    }),
    AFrameHelpersModule.forRoot(),
    //
    AppSharedModule.forRoot(),
    MasterPageModule.forRoot(),
    CommonModule,
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
