import { Component, TemplateRef, ViewChild } from '@angular/core';

// ngx-translate
import { NgxEcSiteTranslateService } from 'ngx-ec-site';
import { NgxEcSiteRouterWithFragmentService } from 'ngx-ec-site';
// import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare let gtag: Function;

//
import { ASceneTmComponent } from '@nx/aframe-helpers';

//
import { MasterPageService } from './master-page/master-page.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isCollapsed: boolean = true;

  @ViewChild(ASceneTmComponent, { static: false }) aSceneTm: ASceneTmComponent;
  // noticeModalRef: BsModalRef;
  // @ViewChild('noticeModal', { static: false }) public noticeModal: TemplateRef<any>;

  constructor(
    public masterPageService: MasterPageService
    // , private angulartics2GoogleTagManager: Angulartics2GoogleTagManager
    , protected ngxEcSiteTranslateService: NgxEcSiteTranslateService
    , protected ngxEcSiteRouterWithFragmentService: NgxEcSiteRouterWithFragmentService
    // , private modalService: BsModalService
  ) {
  }

  public ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.masterPageService.showPreloader$.next(false);

    // this.noticeModalRef = this.modalService.show(this.noticeModal, { animated: false, class: "notice-modal" });
  }
}
