import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { shuffle } from 'lodash';
import { ResponsiveState } from 'ngx-responsive';

import { ASceneTmService } from '../services/a-scene-tm';

declare var THREE: any;

@Component({
  selector: 'a-scene-tm',
  styles: [`
.a-scene-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: calc(100vw - 10px);
}
a-scene {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  width: calc(100vw - 10px);
}`],
  templateUrl: './a-scene-tm.html',
})
export class ASceneTmComponent implements OnInit, OnDestroy, AfterViewInit {

  //
  @ViewChild('sceneMovingKiosk', { static: false }) sceneMovingKiosk: ElementRef<any>;
  @ViewChild('sceneShop', { static: false }) sceneShop: ElementRef<any>;
  @ViewChild('sceneExpoBooth', { static: false }) sceneExpoBooth: ElementRef<any>;
  // @ViewChild('sceneRemoteApp', {static: false}) sceneRemoteApp: ElementRef<any>;

  //
  sceneLast = 'sceneMovingKiosk';
  sceneMapping = {
    '/in-style-you-need': 'sceneShop',
    '/rental-services': 'sceneExpoBooth',
    // '/mobile-app': 'sceneRemoteApp',
  };

  @ViewChild('rig', { static: false }) rig: ElementRef<any>;
  @ViewChild('camera', { static: false }) camera: ElementRef<any>;

  @ViewChild('aScene', { static: false }) aScene: ElementRef<any>;

  @ViewChild('aAssets', { static: false }) aAssets: ElementRef<any>;

  @ViewChildren('kiosk') kiosks: QueryList<ElementRef>;
  @ViewChildren('kioskByUpdate') kioskByUpdates: QueryList<ElementRef<HTMLImageElement>>;

  animationMs: number = 1500;
  animationMsFast: number = 500;
  aAssetsLoadedTimeout: number = 500;
  animationStartDelay: number = 4500;

  isRk: boolean = false;
  cameraRotation: string = '0 0 0';

  kiosksOriginalState: Array<{ position: { x: number, y: number, z: number, }, rotation: { x: number, y: number, z: number, }, scale: { x: number, y: number, z: number, } }> = [];

  imgs = [
    { src: '/assets/imgs/show-in-kiosk/screens/1.png', },
    { src: '/assets/imgs/show-in-kiosk/screens/2.png', },
    { src: '/assets/imgs/show-in-kiosk/screens/3.png', },
    { src: '/assets/imgs/show-in-kiosk/screens/4.png', },
    { src: '/assets/imgs/show-in-kiosk/screens/5.jpg', },
  ];
  bootanimationImgs = [
    // { src: '/assets/imgs/show-in-kiosk/bootanimation/1.png', },
    { src: '/assets/imgs/show-in-kiosk/bootanimation/2.png', },
  ];
  templateImgs = [
    { src: '/assets/imgs/show-in-kiosk/template/template-1.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-2.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-2b.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-2c.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-2d.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-2e.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-3.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-3b.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-3c.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-3d.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-3e.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-3f.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-4.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-4b.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-4c.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-4d.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-4e.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-4f.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-5.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-5b.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-5c.png', },
    { src: '/assets/imgs/show-in-kiosk/template/template-5d.png', },
  ];
  inStyleYouNeedLeftImgs = [
    { src: '/assets/imgs/bg-kiosk/fashion_1_left.jpg' },
    { src: '/assets/imgs/bg-kiosk/fashion_2_left.jpg' },
  ];
  inStyleYouNeedRightImgs = [
    { src: '/assets/imgs/bg-kiosk/fashion_1_right.jpg' },
    { src: '/assets/imgs/bg-kiosk/fashion_2_right.jpg' },
  ];

  aAssetsLoaded: boolean = false;

  cameraAnimationTotal: number = 7;

  lastOrders: Array<number> = [];

  lastAnimatedLineUp: number = 0;

  kiosksPerAngle: number = 22.5;
  kiosksRadius: number = 12;
  kiosksTotal: number = 7;
  kiosksStartRotation: number = 150;
  kiosksConfig = [
  ];
  isRandomImages: boolean = true;

  aSceneTmServiceKioskUpdateSubscription: Subscription;

  constructor(
    public responsiveState: ResponsiveState
    , protected aSceneTmService: ASceneTmService
  ) {
    this.aSceneTmServiceKioskUpdateSubscription = aSceneTmService.kioskUpdate$.subscribe(x => {
      // console.log(x);

      this.isRandomImages = false;

      this.kiosks.forEach((kiosk, i) => {
        if (-1 !== x.kiosksIndex.indexOf(i)) {
          kiosk.nativeElement.querySelectorAll('a-image').forEach((aImage: HTMLImageElement) => {
            aImage.setAttribute('opacity', '0');
          });
        }
      });

      this.kioskByUpdates.forEach((aImage, i) => {
        if (-1 !== x.kiosksIndex.indexOf(i)) {
          // console.log(x.kiosksIndex, i, x.objectUrl);
          aImage.nativeElement.setAttribute('src', x.dataUrl);
          aImage.nativeElement.setAttribute('opacity', '1');
        }
      });
    });

    this.imgs.forEach((img, i) => this.lastOrders.push(i));
    this.templateImgs.forEach((img, i) => this.lastOrders.push(this.imgs.length + i));

    this.responsiveState.device$.subscribe(v => {

    });

    this.isRk = this.responsiveState.getUserAgent().indexOf('rk3288') !== -1;
    if (this.responsiveState.isMobile()) {
      if (this.isRk) {
        this.cameraRotation = '0 240 0';
      } else {
        this.cameraRotation = '0 180 0';
      }
    }

    for (var i = 0; i < this.kiosksTotal; ++i) {
      this.kiosksConfig.push({
        position: `${this.kiosksRadius * Math.sin(THREE.Math.degToRad((i - 3) * this.kiosksPerAngle))} -1 ${-this.kiosksRadius * Math.cos(THREE.Math.degToRad((i - 3) * this.kiosksPerAngle))}`,
        rotation: `0 ${this.kiosksStartRotation - (i - 1) * 30} 0`,
        lineUpLeftTo: `${-8 + 2 * i} -1 ${-8 - 3 * i}`,
        lineUpRightTo: `${8 - 2 * i} -1 ${-8 - 3 * i}`,
      });
    }
    // console.log(this.kiosksConfig);
  }

  ngOnDestroy() {
    this.aSceneTmServiceKioskUpdateSubscription.unsubscribe();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.kiosks.forEach(kiosk => {
        this.kiosksOriginalState.push({
          position: {
            x: kiosk.nativeElement.object3D.position.x,
            y: kiosk.nativeElement.object3D.position.y,
            z: kiosk.nativeElement.object3D.position.z,
          },
          rotation: {
            x: kiosk.nativeElement.object3D.rotation.x,
            y: kiosk.nativeElement.object3D.rotation.y,
            z: kiosk.nativeElement.object3D.rotation.z,
          },
          scale: {
            x: kiosk.nativeElement.object3D.scale.x,
            y: kiosk.nativeElement.object3D.scale.y,
            z: kiosk.nativeElement.object3D.scale.z,
          },
        });
      });
    }, 500);

    if (this.rig && this.rig.nativeElement) {
      this.rig.nativeElement.addEventListener('animationcomplete', (e: CustomEvent) => {
        let eventDetail: { name: string } = e.detail;
        if (eventDetail.name !== 'animation') {
          if (eventDetail.name !== 'animation__rotation_reset') {
            setTimeout(() => {
              this.rig.nativeElement.emit('rotation_reset');
            }, Math.random() * 1000);
          } else {
            setTimeout(() => {
              let nextAnimationName = 'rotation_' + Math.ceil(Math.random() * this.cameraAnimationTotal);
              this.rig.nativeElement.emit(nextAnimationName);
            }, Math.random() * 1000);
          }
        }
      });
      let nextAnimationName = 'rotation_' + Math.ceil(Math.random() * this.cameraAnimationTotal);
      this.rig.nativeElement.emit(nextAnimationName);
    }
  }

  addImage(src: string, id: string): Promise<HTMLImageElement> {
    var el: HTMLImageElement = document.createElement('img');
    el.id = id;
    el.src = src;
    this.imgs.push({ src: id });
    this.aAssets.nativeElement.appendChild(el);

    return Promise.resolve(el);
  }

  updateKioskImg(kioskIndex: number, id: string) {
    this.kioskByUpdates.forEach((aImage, i) => {
      aImage.nativeElement.setAttribute('opacity', '0');
    });
    this.kiosks.filter((k, i) => kioskIndex === i).forEach(kiosk => {
      kiosk.nativeElement.querySelectorAll('a-image').forEach((aImage: HTMLImageElement) => {
        aImage.setAttribute('opacity', (id === aImage.src) ? '1' : '0');
      });
    })
  }

  _animateLineUpLeft() {
    this.kiosks.forEach((kiosk) => {
      kiosk.nativeElement.emit('lineup_left_position');
      kiosk.nativeElement.emit('lineup_left_rotation');
    });
  }

  _animateLineUpRight() {
    this.kiosks.forEach((kiosk) => {
      kiosk.nativeElement.emit('lineup_right_position');
      kiosk.nativeElement.emit('lineup_right_rotation');
    });
  }

  _animateLineUpRound() {
    this.kiosks.forEach((kiosk) => {
      kiosk.nativeElement.emit('lineup_round_position');
      kiosk.nativeElement.emit('lineup_round_rotation');
    });
  }

  randomImages(): void {
    if (this.isRandomImages) {
      let shuffled = shuffle(this.lastOrders);

      this.kiosks.forEach((kiosk, index) => {
        if (kiosk.nativeElement.querySelectorAll('a-image').length > 0) {
          kiosk.nativeElement.querySelectorAll('a-image')[this.lastOrders[index]].setAttribute('opacity', '0');
          kiosk.nativeElement.querySelectorAll('a-image')[shuffled[index]].setAttribute('opacity', '1');
        }
      });

      this.lastOrders = shuffled;
    }
  }

  onAAssetsLoaded($event): void {
    // console.log('onAAssetsLoaded', $event);

    setTimeout(() => {
      this.aAssetsLoaded = true;
      setTimeout(() => {
        this.randomImages();

        setInterval(() => {
          let rand = Math.random();
          this.randomImages();

          let animatedLineUp = Math.ceil(rand * 3);
          while (this.lastAnimatedLineUp === animatedLineUp) {
            animatedLineUp = Math.ceil(Math.random() * 3);
          }
          switch (animatedLineUp) {
            case 1: this._animateLineUpLeft(); break;
            case 2: this._animateLineUpRight(); break;
            case 3: this._animateLineUpRound(); break;
          }
          this.lastAnimatedLineUp = animatedLineUp;
        }, this.animationMs * (1 + Math.random() * 2));
      }, this.animationStartDelay);
    }, this.aAssetsLoadedTimeout);
  }


  public switchScene(url: string) {
    // console.log("switchScene", url, this.sceneMapping, this[this.sceneMapping[url]]);
    if (this[this.sceneMapping[url]]) {
      if (this.sceneLast && this.sceneLast !== this[this.sceneMapping[url]]) {
        this[this.sceneLast].nativeElement.setAttribute('visible', false);
        this[this.sceneMapping[url]].nativeElement.setAttribute('visible', true);
        this[this.sceneMapping[url]].nativeElement.emit('init');
        this[this.sceneMapping[url]].nativeElement.querySelectorAll('[animation__init]').forEach(x => {
          x.emit('init');
        });
        this.sceneLast = this.sceneMapping[url];
      }
    } else {
      if (this.sceneLast && this.sceneLast !== 'sceneMovingKiosk') {
        this[this.sceneLast].nativeElement.setAttribute('visible', false);
        this.sceneMovingKiosk.nativeElement.setAttribute('visible', true);
        this.sceneLast = 'sceneMovingKiosk';
      }
      this.randomImages();
    }
  }
}
