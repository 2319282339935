import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
//
import { InsidePageDefaultComponent } from './inside-page-default/inside-page-default.component';
//
import { PageTitleComponent } from './components/page-title/page-title.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [
    InsidePageDefaultComponent,
    PageTitleComponent,
    BreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild({
      useDefaultLang: true,
      isolate: false,
    }),
    // CommonComponentsModule
    // TranslateModule
  ],
  exports: [
    RouterModule,
    TranslateModule,
    InsidePageDefaultComponent,
    PageTitleComponent,
    BreadcrumbComponent,
  ]
})
export class MasterPageModule {
  static forRoot(providers = []): ModuleWithProviders {
    return {
      ngModule: MasterPageModule,
      providers: [
        ...providers,
      ],
    }
  }
}
