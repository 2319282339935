import { Renderer2, ElementRef, ViewChildren, QueryList, Sanitizer, SecurityContext, Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { shuffle } from 'lodash';
//
import { ASceneTmService } from '@nx/aframe-helpers';

import screenLayout from './screen-layouts.json';

var screenLayoutReplaced = screenLayout.items;
screenLayoutReplaced.forEach(i => i.thumbnail = '/' + i.thumbnail.replace('/imgs/template/', '/imgs/show-in-kiosk/template/'));

const NO_OF_GROUPS: number = 2;
const NO_OF_KIOSKS: number = 7;
const NO_OF_TIMESLOTS: number = 4;
const TIMESLOT_SECONDS: number = 60 / NO_OF_TIMESLOTS;

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss']
})
export class MobileAppComponent implements OnInit {

  @ViewChildren('fileUploadImgs') fileUploadImgs: QueryList<ElementRef<HTMLImageElement>>;

  isCollapsed: boolean = true;

  secondsClock$ = interval(1000).pipe(map(x => (new Date()).getSeconds()));

  options = {
    layout: shuffle(screenLayoutReplaced).slice(0, 2),
    groups: {
      kiosk: (new Array(NO_OF_KIOSKS)).fill({}).map((x, i) => ({
        name: i,
        group: Math.floor(Math.random() * NO_OF_GROUPS)
      })),
      group: (new Array(NO_OF_GROUPS)).fill('').map((x, i) => i),
    },
    scheduler: ['Immediate', ...(new Array(NO_OF_TIMESLOTS)).fill('').map((x, i) => "@: " + TIMESLOT_SECONDS * i)],
    approval: ['Admin', 'User'],
  };

  disabled = {
    groups: false,
    scheduler: false,
    approval: false,
  };

  selected = {
    layout: screenLayoutReplaced[0],
    groups: {
      kiosk: (new Array(NO_OF_KIOSKS)).fill(0).map((x, i) => Math.floor(Math.random() * NO_OF_GROUPS)),
      target: 0,
    },
    scheduler: 'Immediate',
    approval: null,
  }

  fileUploads = [];

  kioskLayoutImage: HTMLImageElement = this.renderer2.createElement('img');
  kioskLayoutCanvas: HTMLCanvasElement = this.renderer2.createElement('canvas');
  kioskLayoutContext: CanvasRenderingContext2D = this.kioskLayoutCanvas.getContext('2d');
  kioskLayoutDataUrl = '';
  kioskLayoutObjectUrl = '';

  constructor(
    protected sanitizer: Sanitizer
    , protected renderer2: Renderer2
    , protected aSceneTmService: ASceneTmService
  ) {
    console.log(this.options);
  }

  ngOnInit() {
    this.layoutSelected(null, this.selected.layout);
  }

  kioskGroupChanged($event: Event, kioskIndex: number, groupIndex: number): void {
    this.selected.groups.kiosk[kioskIndex] = groupIndex;
    console.log(this.selected.groups.kiosk);
  }

  schedulerChanged($event: Event, option: string): void {
    this.selected.scheduler = option;
  }

  targetGroupChanged($event: Event, index: number): void {
    this.selected.groups.target = index;
  }

  layoutSelected(_event: Event, layout: any): void {
    console.log(layout);
    this.selected.layout = layout;
    let fileUploads = layout.sections.filter(x => (x.type === 'Video' || x.type === 'Image' || x.type === 'Html'));
    fileUploads.forEach(x => {
      x.model = null;
      x.url = null;
    });
    this.fileUploads = fileUploads;
  }

  fileUploaded($event: Event, fileUpload, i) {
    let fileEle: any = $event.target;
    if (fileEle.files && fileEle.files[0]) {
      const file = fileEle.files[0];

      fileUpload.url = new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = e => {
          resolve(this.sanitizer.sanitize(SecurityContext.URL, reader.result));
        };

        reader.readAsDataURL(file);
      });
    }
  }

  onSaveClicked($event: Event) {
    this.kioskLayoutCanvas.width = 1080;
    this.kioskLayoutCanvas.height = 1920;
    this.kioskLayoutImage.onload = () => {
      // Draw the base
      this.kioskLayoutContext.drawImage(this.kioskLayoutImage, 0, 0, 1080, 1920);

      this.selected.layout.sections.filter(x => (x.type === 'Video' || x.type === 'Image' || x.type === 'Html')).forEach((section, sectionIndex) => {
        let imgRef = this.fileUploadImgs.find((e, i) => sectionIndex === i);
        if (imgRef) {
          this.kioskLayoutContext.clearRect(this._calcCss(section.x), this._calcCss(section.y), this._calcCss(section.w), this._calcCss(section.h));
          this.kioskLayoutContext.drawImage(imgRef.nativeElement, this._calcCss(section.x), this._calcCss(section.y), this._calcCss(section.w), this._calcCss(section.h));
        }
      });

      this.kioskLayoutDataUrl = this.kioskLayoutCanvas.toDataURL('image/png');
      this.kioskLayoutCanvas.toBlob((b) => {
        this.kioskLayoutObjectUrl = URL.createObjectURL(b);

        console.log('saved');
      });
    }

    this.kioskLayoutImage.src = this.selected.layout.thumbnail;
  }

  private _calcCss(cssCalc: string, width: number = 1080, height: number = 1920): number {
    let weighted = cssCalc.replace('var(--h)', height.toString()).replace('var(--w)', width.toString()).replace(/^calc\(/, '').replace(/\)$/, '');

    return (new Function('return ' + weighted))();
  }

  onLaunchClicked($event: Event) {
    console.log(this.selected.groups);
    let index = [];
    this.selected.groups.kiosk.forEach((x, i) => {
      if (x === this.selected.groups.target) {
        index.push(i);
      }
    });
    this.aSceneTmService.updateKioskInGroup(index, this.kioskLayoutDataUrl, this.kioskLayoutObjectUrl);
  }

  onRequestApprovalClicked($event: Event) {
    //
  }

}
