import { EventManager } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ASceneTmService {

  private kioskUpdateSource = new Subject<{ kiosksIndex: number[], dataUrl: string, objectUrl: string }>();
  public kioskUpdate$ = this.kioskUpdateSource.asObservable();

  updateKioskInGroup(kiosksIndex: number[], dataUrl: string, objectUrl: string) {
    this.kioskUpdateSource.next({ kiosksIndex, dataUrl, objectUrl });
  }

}
