import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

//
import { TranslateModule } from '@ngx-translate/core';
//
import { MasterPageModule } from '../master-page/master-page.module';
//

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild({
      useDefaultLang: true,
      isolate: false,
    }),
    MasterPageModule,
  ],
  exports: [
    RouterModule,
    TranslateModule,
    MasterPageModule,
    FormsModule
  ]
})
export class AppSharedModule {
  static forRoot(providers = []): ModuleWithProviders {
    return {
      ngModule: AppSharedModule,
      providers: [
        ...providers,
      ],
    }
  }
}
