import { Component, OnInit, ViewChild } from '@angular/core';
//
import { RecaptchaComponent } from 'ng-recaptcha';

import { NgxEcSiteFormContactUsService } from 'ngx-ec-site';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {

  @ViewChild(RecaptchaComponent, { static: false }) recaptchaComponent: RecaptchaComponent;
  formData = this.ngxEcSiteFormContactUsService.formData;

  constructor(
    public ngxEcSiteFormContactUsService: NgxEcSiteFormContactUsService
  ) {

  }

  submitForm() {
    console.log(this.ngxEcSiteFormContactUsService.formData);
    this.ngxEcSiteFormContactUsService.submitForm(this.recaptchaComponent).then(x => console.log(x, 'submitForm then')).catch(console.warn);
  }
}
